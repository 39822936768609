import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Footer from "../components/footer"

const Why = () => (
  <Layout>
    <SEO
      lang="it"
      title="Perche' chiedere sostegno psicologico | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="I motivi per cui chiedere sostegno psicologico di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div>
        <Link to="/">
          <span>back</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#f8f8f8">
      <div style={{ paddingTop: "64px" }}>
        <SectionSubtitle text="Perche' chiedere sostegno psicologico?" />
        <div className="paddings-lr why-content">
          <p>
            Il sostegno psicologico è finalizzato al supporto della persona in
            situazioni di vita specifiche.
          </p>
          <p>
            Ognuno di noi può attraversare periodi particolarmente stressanti,
            magari per stanchezza o perché più problemi si presentano nello
            stesso momento. I motivi che spingono una persona a chiedere aiuto
            ad uno psicologo sono diversi.
          </p>
          <p>
            Di solito riguardano cambiamenti importanti della vita, i quali
            possono essere difficili da affrontare da soli. In questi casi ci si
            può sentire incapaci, la difficoltà può apparire come uno scoglio
            insormontabile, e si rischia di avvilirsi.
          </p>
          <p>
            Questa situazione può mandarci in "tilt" e ci si può sentire confusi
            senza riuscire a d uscire dallo stallo e a risolvere il problema,
            ripercuotendosi negativamente in diversi ambiti della vita e
            aumentando il malessere e i sentimenti di inadeguatezza.
          </p>
          <p>
            Il sostegno psicologico ha come finalità la gestione della
            difficoltà, è teso a definire e inquadrare la problematica,
            individuare e sviluppare le capacità di soluzione e le strategie che
            la persona ha già in sé (risorse personali) ma che non riesce a
            mettere in campo da solo perché schiacciato dalla preoccupazione.
          </p>
          <p>
            Il percorso è quindi teso a: uscire dal momento di crisi; migliorare
            l'autostima e il senso di possibilità, attivare soluzioni per
            modificare la situazione; accettare il cambiamento per poter trovare
            un nuovo modo di vivere al meglio la propria vita.
          </p>
        </div>
      </div>
    </Section>
    <Footer />
  </Layout>
)

export default Why
